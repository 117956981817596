import React from 'react';

const Header = () => {
    return(
        <header className="header">
            <div className="header_top">
                Новости Усть-Каменогорска и ВКО
            </div>
            <div className="header_middle">
                <div className="header_info">
                    <a href="https://yk-news.kz/" target="_blank">
                        <div className="logo">
                            <div className="logo_img"></div>
                            <h2>Ведущий сайт Усть-Каменогорска</h2>
                        </div>
                    </a>
                </div>
            </div>
        </header>
    )
}

export default Header;