import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Header from './components/header';
import ChartSection from './components/ChartSection';
import NumberSection from './components/NumberSection';
import AdsSection from './components/AdsSection';
import VarianSection from './components/varianSection';
import FooterSection from './components/footerSection';

import './css/bootstrap.css';
import './css/style.scss';

class Front extends Component {
    render() {
        return (
            <div>
                <Header />
                <main>
                    <NumberSection />
                    <ChartSection />
                    <AdsSection />
                    <VarianSection />
                    <FooterSection />
                </main>
            </div>
        )
    }
}

ReactDOM.render(
    <Front />,
    document.getElementById('app')
);