import React from 'react';

import FullImage from '../assets/main_mob_full-min.png'

const AdsSection = () => {
    return(
        <section className="front_section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section_title">Реклама на сайте YK-news.kz</h2>
                        <div className="article_info">
                            <label>Статьи</label>
                            <p>
                                Объём: <i>до 5000 знаков.</i>
                            </p>
                            <p>
                                Фотографии: <i>до 7-ми в тексте</i>
                            </p>
                            <p className="p_padding">
                                <i>до 15-ти в фотогалерее </i>
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="r_container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="r_label">
                                        <i className="ico ico_pattern ico_desktop"></i> 
                                        Полная версия
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="r_label">
                                        <i className="ico ico_pattern ico_mobile"></i> 
                                        Мобильная версия
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="full_image mobile_hid"></div>
                        <img src={FullImage} alt="Реклама на сайте YK-news" className="image_fluid pc_hid main_mob_img" />
                    </div>
                    <div className="col-12 col_dop">
                        <label>Дополнительная услуга:</label>
                        <div className="info">
                            Удержание статьи на верхних позициях в ленте новостей - <strong><span className="red">10.000</span> тг./день</strong>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AdsSection;