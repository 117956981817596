import React from 'react';

const NumberSection = () => {
    return(
        <section className="front_section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section_title">Среднее количество пользователей</h2>
                    </div>
                    <div className="col-lg-4">
                        <div className="count_stat">
                            <div className="num">25 000</div>
                            <div className="label">в день</div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="count_stat">
                            <div className="num">400 000</div>
                            <div className="label">в месяц</div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="count_stat">
                            <div className="num">3 700 000</div>
                            <div className="label">в год</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NumberSection;