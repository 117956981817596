import React from 'react';
import Chart from './chart';

const chart1 = [
    {
        angle: 1.0,
        color: '#c63a04',
        className: 'age_1',
        name: 'Alt Label'
    },
    {
        angle: 1.4,
        color: '#95be00',
        className: 'age_2'
    },
    {
        angle: 2.4,
        color: '#de7d04',
        className: 'age_3'
    },
    {
        angle: 2.3,
        color: '#f2bd0d',
        className: 'age_4'
    },
    {
        angle: 2.8,
        color: '#0498be',
        className: 'age_5'
    },
    {
        angle: 1.1,
        color: '#a74aa3',
        className: 'age_6'
    }
]

const chart2 = [
    {
        angle: 1.7,
        color: '#f2bf10',
        className: 'gender_1'
    },
    {
        angle: 2,
        color: '#9cc500',
        className: 'gender_2'
    }
]

class ChartSection extends React.Component {
    
    componentDidMount() {
        let metricItems = document.querySelectorAll('.metrik_list li[data-hover]');
        metricItems.forEach((item) => {

            item.addEventListener('mouseover', () => {
                let graphClass = item.getAttribute('data-hover'),
                    wrapperClass = item.parentNode.getAttribute('data-type');
                document.querySelector(graphClass).classList.add('active');
                document.querySelector(wrapperClass).classList.add('active');
            });

            item.addEventListener('mouseout', () => {
                let graphClass = item.getAttribute('data-hover'),
                    wrapperClass = item.parentNode.getAttribute('data-type');
                document.querySelector(graphClass).classList.remove('active');
                document.querySelector(wrapperClass).classList.remove('active');
            });
        });
    }

    render() {
        return(
            <section className="front_section section_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="section_title title_margin">Аудитория сайта</h2>
    
                            <div className="metric_block">
                                <div className="item">
                                    <label className="metrik_name">Возраст</label>
                                </div>
                                <div className="item">
                                    <Chart classData='age_graph' chart={chart1} />
                                </div>
                                <div className="item">
                                    <ul className="metrik_list" data-type=".age_graph">
                                        <li className="li_red" data-hover=".age_1">
                                            <span className="val">18-24 года</span>
                                            <span className="sum">4,79%</span>
                                        </li>
                                        <li className="li_green" data-hover=".age_2">
                                            <span className="val">25-34 года</span>
                                            <span className="sum">19,8%</span>
                                        </li>
                                        <li className="li_orange" data-hover=".age_3">
                                            <span className="val">35-44 года</span>
                                            <span className="sum">22,3%</span>
                                        </li>
                                        <li className="li_yellow" data-hover=".age_4">
                                            <span className="val">45-54 года</span>
                                            <span className="sum">21,9%</span>
                                        </li>
                                        <li className="li_blue" data-hover=".age_5">
                                            <span className="val">55 лет и старше</span>
                                            <span className="sum">28,4%</span>
                                        </li>
                                        <li className="li_purple" data-hover=".age_6">
                                            <span className="val">Остальные</span>
                                            <span className="sum">5,5%</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="metric_block">
                                <div className="item">
                                    <label className="metrik_name">Пол</label>
                                </div>
                                <div className="item">
                                    <Chart classData='gender_graph' chart={chart2} />
                                </div>
                                <div className="item">
                                    <ul className="metrik_list" data-type=".gender_graph">
                                        <li className="li_yellow" data-hover=".gender_1">
                                            <span className="val">Мужской</span>
                                            <span className="sum">47,1%</span>
                                        </li>
                                        <li className="li_green" data-hover=".gender_2">
                                            <span className="val">Женский</span>
                                            <span className="sum">52,9%</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
    
                            <div className="metric_desc">
                                <span className="red">*</span>Информация предоставлена общепризнанной системой статистики Yandex Metrika.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ChartSection;