import React from 'react';

import imgDesk from '../assets/newmainpc2-min.png';
import imgMob from '../assets/bt_mb_n-min.png';
import imgMobMob from '../assets/bt_mob-mob-min.png';

class VariantSection extends React.Component {
    constructor() {
        super();

        this.state = {
            variant: 2
        }
    }

    typeClick(event) {
        console.log(event)
    }

    render() {
        return(
            <section className="front_section variant_h_section section_border">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="var_info">
                                <h2 className="red">Баннеры</h2>
                                <p>Стоимость из расчета ротации 25%</p>
                                <p>
                                    Формат баннера: <i>анимированный баннер в HTML5, размером 480 на 290 пикселей</i>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col_variant">
                            <div className="row">
                            <div className="col-6 col_v">
                                <div className="v_arr"></div>
                                <div 
                                    onClick={() => this.setState({ variant: 2})} 
                                    className={'variant_type type_mobile ' + (this.state.variant == 2 ? 'active' : '')}>
                                        <div className="varint_btn">
                                            <div className="ico_wrap">
                                                <i className="ico ico_pattern ico_mobile"></i>
                                            </div>
                                            <div className="name_line">
                                                <span>Мобильная версия</span>
                                            </div>
                                        </div>
                                        <div className="varinat_info">
                                            <div className="num">
                                                78%
                                            </div>
                                            <div className="label">
                                                посетителей читают m.YK-news
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col_v">
                                    <div 
                                        onClick={() => this.setState({ variant: 1})} 
                                        className={'variant_type type_desctop ' + (this.state.variant == 1 ? 'active' : '')}>
                                        <div className="varint_btn">
                                            <div className="ico_wrap">
                                                <i className="ico ico_pattern ico_desktop"></i>
                                            </div>
                                            <div className="name_line">
                                                <span>Полная версия</span>
                                            </div>
                                        </div>
                                        <div className="varinat_info">
                                            <div className="num">
                                                22%
                                            </div>
                                            <div className="label">
                                                посетителей используют YK-news
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col_variant_img">
                            <div className="var_picture">
                                <img 
                                    src={imgDesk} 
                                    alt="Посетителей используют YK-news" 
                                    className={'image_fluid varint_img_desc ' + ((this.state.variant == 1 ? 'show' : 'hide'))}/>
                                <img 
                                    src={imgMob} 
                                    alt="Посетителей используют YK-news" 
                                    className={'image_fluid mobile_hid ' + ((this.state.variant == 2 ? 'show' : 'hide'))}/>
                                <img 
                                    src={imgMobMob} 
                                    alt="Посетителей используют YK-news" 
                                    className={'image_fluid pc_hid ' + ((this.state.variant == 2 ? 'show' : 'hide'))}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default VariantSection;