import React from 'react';

const FooterSection = () => {
    return(
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Контакты в Усть-Каменогорске</h4>
                        <ul>
                            <li>
                                <i className="ico ico_location ico_pattern"></i>
                                Усть-Каменогорск, ул. Казахстан, 87/4
                            </li>
                            <li>
                                <i className="ico ico_mail ico_pattern"></i>
                                <a href="mailto:reklama@idregion.kz">reklama@idregion.kz</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <h4>Отдел рекламы</h4>
                        <ul>
                            <li>
                                <i className="ico ico_phone ico_pattern"></i>
                                <span>Телефон:</span> <a href="tel:+7 (7232) 70-40-42"> +7 (7232) 70-40-42</a>
                            </li>
                            <li>
                                <i className="ico ico_wht ico_pattern"></i>
                                <span>WhatsApp:</span> <a href="https://api.whatsapp.com/send?phone=+77053161213">+7 (705) 316-12-13</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterSection;